.summarizer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.modeConfigs {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
    /* align-self: flex-start; */
    /* margin-left: 9.3%; */
}

.modeButtons {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 12px;
}

.summaryLengthInput {
    width: 133px;
}

.bottomContent {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 10%;
}

.button {
    color: var(--buttonColor) !important;
    background-color: var(--buttonBackground) !important;
    opacity: 0.7;
}

.submitBtnWrap {
    display: flex;
    justify-content: right;
    width: 100%;
}

.submitBtn {
    top: -40px;
}

.button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.7;
}

.button:hover {
    transition: 0.3s;
    opacity: 1;
    color: var(--buttonColor) !important;
    background-color: var(--buttonBackground) !important;
}

.inputs {
    display: flex;
    justify-content: center;
    width: 100%;
}

.input {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.outputWrap textarea,
.inputWrap textarea {
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto !important;
    resize: none;
    font-family: 'Merriweather', serif;
    font-size: 14px;
    font-weight: normal;
}

.inputWrap {
    height: var(--textareaHeight);
}

.outputWrap {
    width: 40%;
    height: var(--textareaHeight);
}

.fileUploadContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 98%;
    padding: 1%;
    border: lightgray dashed;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.summaryLength {
    display: flex;
    align-items: center;
}

.rowLength>label {
    display: flex;
    align-items: center;
}

.summaryLengthLabel {
    font-family: 'Libre Franklin', sans-serif !important;
    font-weight: bold !important;
    color: black !important;
    font-size: large !important;
    margin-right: 12px;
}

.modeLabel {
    font-family: 'Libre Franklin', sans-serif !important;
    font-weight: bold !important;
    color: black !important;
    font-size: large !important;
}