@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;500&family=Merriweather:ital,wght@0,700;1,900&display=swap');

:root {
  --buttonColor: white;
  --buttonBackground: #6B38FB;
  --textareaHeight: 300px;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Merriweather', serif;
  font-size: 16px;
}

h1,
h3 {
  font-family: 'Merriweather', serif;
  font-style: italic;
}

h2 {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: lighter;
}

h4 {
  font-family: 'Libre Franklin', sans-serif;
}

button {
  font-family: 'Libre Franklin', sans-serif !important;
  font-size: 14px !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}