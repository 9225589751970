.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-style: dashed;
  padding-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}