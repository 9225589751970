.header {
  display: flex;
  justify-content: center;
  padding: 3rem;
}

.logo {
  width: 300px;
  overflow: hidden;
  margin: 0;
}

.userButtonsContainer {
  position: absolute !important;
  right: 0;
  margin: 40px !important;
}

.userFormBtn {
  color: white;
  background-color: #252525 !important;
  margin-left: 20px !important;
}